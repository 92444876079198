<template>
  <div>
    <Menu />

    <section>
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="estates-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                  <h1 class="display-2 pb-5"><strong>Estates</strong></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="events-blocks">
      <div class="container g-5">
        <div class="row pt-5">

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Franschhoek</h2>
              <h4 class="text-start py-3">
                <span>Ranked by Time Magazine as one of the “World’s Greatest Places”, Franschhoek is a picturesque village surrounded by impressive mountains and lush green vineyards. The hospitable town has a rich history and is known as the food and wine capital of the country. Browse through the multiple luxury estates to find the one that you will call home.</span>
              </h4>
              <RouterLink to="/properties" @click.native="$store.state.search.text = 'franschhoek'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Val de Vie Estate</h2>
              <h4 class="text-start py-3">
                <span>Discover a life worth living at Val de Vie Estate. Set in the heart of the beautiful Paarl-Franschhoek Valley, it is regarded as South Africa’s safest luxury lifestyle estate. Val de Vie offers a mix of property options including sectional title apartments, townhouses, lifestyle farms, homes that overlook lakes and a river, and the Gentleman’s Estate.</span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'val de vie'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Pearl Valley Golf & Country Estate</h2>
              <h4 class="text-start py-3">
                <span>The Pearl Valley properties are positioned around the iconic Jack Nicklaus Signature Golf Course. Designed by the Golden Bear himself, the world’s pre-eminent golf course designer. Known for their modern architectural style, each property boasts its own distinctive view of the surrounding Drakenstein and Simonsberg mountain ranges.</span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'pearl valley'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Boschenmeer Golf Estate</h2>
              <h4 class="text-start py-3">
                <span>Enjoy luxurious country living at Boschenmeer Estate. Located in the Western Cape’s scenic Paarl/Franschhoek Valley and framed by the Drakenstein mountains, almost all the residences in this secure eco-sensitive estate overlook the lush golf course, while also enjoying manicured lawns, tranquil riverside settings and awe-inspiring mountain views. </span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'boschenmeer'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Deltacrest Estate</h2>
              <h4 class="text-start py-3">
                <span>Savour a unique lifestyle experience in the heart of the Winelands. Deltacrest Country Estate is an equestrian estate situated in the centre of the beautiful Franschhoek and Stellenbosch vineyard region. This upmarket and intimate lifestyle estate offers a limited-edition real estate experience with a wealth of facilities and activities. </span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'deltacrest'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Winelands Estate</h2>
              <h4 class="text-start py-3">
                <span>Live your dream life at Winelands Estate. Located in the Drakenstein Valley, breathtaking mountain views are standard. There are 33 freehold stands, sized between 4, 000 and 11, 000 square meters, each on its own portion of vineyard or olive grove, providing a unique combination of wide-open spaces and supreme privacy.<br/><br/></span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'winelands'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Uitgezocht Boutique Lifestyle Estate</h2>
              <h4 class="text-start py-3">
                <span>The stunning properties of Uitgezocht Estate are set among private vineyards on a beautiful slope just outside of Paarl. Aside from the impressive mountain views, the homes offer absolute privacy and an intimate sense of community for the young and old. Uitgezocht Boutique Lifestyle Estate is vineyard country living at its best.</span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'uitgezocht'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>
          
          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Wilde Paarde Country Estate</h2>
              <h4 class="text-start py-3">
                <span>The estate is set on the southern side of Paarl, conveniently located opposite the acclaimed Boschenmeer Golf Estate, and offers dramatic views of the surrounding mountain ranges. Named after the Wildepaardejagt River which runs through the southern edge of the estate, it is the impressive new jewel in the crown of the Cape Winelands.</span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'wilde paarde'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4">
            <div class="banner-section lifestyle-block switch-bg-lblue p-3 h-100 bord-radius">
              <h2>Drakenzicht<br/>Estate</h2>
              <h4 class="text-start py-3">
                <span>Life is beautiful at Drakenzicht Estate. Nestled within the Klein Drakenstein mountains in Paarl, one of the Cape’s most beautiful areas, this luxury “green” estate seamlessly blends beautiful natural scenery with secure family estate living. Eco-friendly and luxurious, Drakenzicht assures residents that they can enjoy safe, modern living in an idyllic location.</span>
              </h4>
              <RouterLink to="/properties" @click.native = "$store.state.search.text = 'boschenmeer'" class="btn cta-btn bord-radius">View Properties</RouterLink>
            </div>
          </div>
          
        </div>
      </div>
    </section> 
    <Footer />
  </div>
</template>
<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>